import {Pipe, PipeTransform} from '@angular/core';
import {ProposalLanguageRepresentation, RequestedPropertyRepresentation} from '@aztrix/sdk';

import {getPropertyTypeDescriptions} from '../../helpers/proposal-functions';

@Pipe({name: 'proposalPropertyDescriptions'})
export class ProposalPropertyDescriptionsPipe implements PipeTransform {
  transform(
    property: RequestedPropertyRepresentation,
    language: ProposalLanguageRepresentation
  ): string[] {
    return <string[]>getPropertyTypeDescriptions(property, language)
      .map((ptd) => ptd?.description)
      .filter((desc) => !!desc);
  }
}
