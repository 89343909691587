import {
  Agreement,
  AgreementData,
  AgreementDocument,
  AgreementProperty,
  Profile,
  Property,
} from '@aztrix/models';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  AgreementRepresentation,
  ProfileRepresentation,
  SigningOrderRecipientRepresentation,
  UpdateAgreementRepresentation,
} from '@aztrix/sdk';

import {typeFilter} from '../util/filter.util';
import {getPropertyOfType, getPropertyTuples} from './context-functions';
import {hasEqualValue} from './property-functions';
import {getRecipientPropertyValue} from './recipient-functions';

export function isAgreement(agreement: any): agreement is Agreement {
  return agreement?.proposalId;
}

export function isAgreementData(agreementData: any): agreementData is AgreementData {
  return agreementData?.agreementProperties;
}

export function isSubscriber(
  agreement: Agreement | AgreementRepresentation,
  profile: Profile
): boolean {
  return agreement?.subscriberId === profile?.id;
}

export function isExpired(agreement: Agreement): boolean {
  return agreement?.expirationTimestamp > 0;
}

export function outOfSyncPropertiesForProfile(
  agreementProperties: Property[],
  profile: Profile
): Property[] {
  if (profile) {
    const profileProperties = getPropertyTuples(
      profile.propertyContexts,
      typeFilter(...agreementProperties.map((property) => property.type))
    ).map((tuple) => tuple.property);
    const equalProperties = agreementProperties.reduce(
      (propertyArray, agreementProperty) => {
        const equalValueProperties = profileProperties.filter(
          (profileProperty) =>
            profileProperty.type === agreementProperty.type &&
            hasEqualValue(profileProperty, agreementProperty)
        );

        if (equalValueProperties.length) {
          propertyArray.push(agreementProperty);
        }
        return propertyArray;
      },
      <Property[]>[]
    );
    const profilePropertyTypes = profileProperties.map((p) => p?.type);
    return agreementProperties.filter(
      (property) =>
        !equalProperties.includes(property) && profilePropertyTypes.includes(property.type)
    );
  }

  return [];
}

export function propertyOutOfSync(
  agreementProperty: Property | undefined,
  profile: Profile | ProfileRepresentation | undefined
): boolean {
  if (!profile || !agreementProperty) {
    return false;
  }
  const profileProperties = getPropertyTuples(
    profile.propertyContexts,
    typeFilter(agreementProperty.type)
  ).map((tuple) => tuple.property);

  const equalValueProperties = profileProperties.filter(
    (profileProperty) =>
      profileProperty?.type === agreementProperty.type &&
      hasEqualValue(profileProperty, agreementProperty)
  );

  return equalValueProperties.length === 0;
}

export function outOfSyncAgreementsForProperty(
  agreements: Agreement[],
  property: Property,
  profile: Profile
): Agreement[] {
  return agreements.filter((agreement) =>
    outOfSyncPropertiesForProfile(
      agreement.agreementData.agreementProperties.map((a) => a.property),
      profile
    ).some((agreementProperty) => agreementProperty.type === property.type)
  );
}

export function prefillAgreementPropertiesWithProfile(
  agreementProperties: AgreementProperty[],
  profile: Profile
): AgreementProperty[] {
  for (const agreementProperty of agreementProperties) {
    const profileProperty = getPropertyOfType(profile, agreementProperty.property.type);
    if (profileProperty) {
      agreementProperty.property = profileProperty;
    }
  }

  return agreementProperties;
}

export function prefillAgreementPropertiesWithRecipient(
  agreementProperties: AgreementPropertyRepresentation[],
  recipient: SigningOrderRecipientRepresentation
): AgreementPropertyRepresentation[] {
  for (const agreementProperty of agreementProperties) {
    const recipientPropertyValue = getRecipientPropertyValue(
      recipient,
      agreementProperty.property?.type
    );
    agreementProperty.property = {...agreementProperty.property, value: recipientPropertyValue};
  }

  return agreementProperties;
}

export function agreementDocumentByProposalDocumentId(
  documentId: string | undefined,
  agreementDocuments?: AgreementDocument[] | AgreementDocumentRepresentation[]
): AgreementDocument | AgreementDocumentRepresentation | undefined {
  return (agreementDocuments || []).find(
    (agreementDocument) => agreementDocument.proposalDocumentId === documentId
  );
}

export function agreementHasEqualValue(
  oldAgreement: AgreementRepresentation | null | undefined,
  newAgreement: UpdateAgreementRepresentation | null | undefined
) {
  if (!oldAgreement?.agreementData || !oldAgreement.agreementData?.confirmed || !newAgreement) {
    return false;
  }

  if (oldAgreement.agreementData.language !== newAgreement.language) {
    return false;
  }

  if (oldAgreement.verificationValue !== newAgreement.verificationValue) {
    return false;
  }

  const oldAgreementDataProperties = oldAgreement.agreementData.agreementProperties || [];
  const newAgreementDataProperties = newAgreement.agreementProperties || [];

  if (oldAgreementDataProperties.length !== newAgreementDataProperties.length) {
    return false;
  }

  for (const oldAgreementDataProperty of oldAgreementDataProperties || []) {
    const newAgreementDataProperty = newAgreementDataProperties.find(
      (n) => n.requestedPropertyId === oldAgreementDataProperty.requestedPropertyId
    );
    if (
      !newAgreementDataProperty ||
      !hasEqualValue(oldAgreementDataProperty.property, newAgreementDataProperty.property)
    ) {
      return false;
    }
  }

  return true;
}
