import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {
  ProposalLanguageItemRepresentation,
  ProposalLanguageItemWithPropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageItemWithTextTemplateRepresentation,
  ProposalLanguageRepresentation,
  ProposalLanguageStepRepresentation,
  ProposalLanguageTextTemplateRepresentation,
  RequestedPropertyDescriptionRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';

import {getItem, getItemIndex} from './proposal-functions';

/**
 * Gets all steps that the current step can skip to
 *
 * @param step the current proposal step
 * @param steps all proposalSteps
 */
export function stepCanSkipTo(
  step: ProposalLanguageStepRepresentation,
  items: ProposalLanguageItemRepresentation[],
  steps: ProposalLanguageStepRepresentation[]
): ProposalLanguageStepRepresentation[] {
  return [...(steps || [])].filter(
    (s) => _orderIndex(s, items) > _orderIndex(step, items) || s.id === step?.skipTo
  );
}

function _stepIndex(
  orderIndex: number | undefined,
  items: ProposalLanguageItemRepresentation[]
): number | undefined {
  const sortedSteps = (items || [])
    .filter((i) => i.type !== ProposalLanguageItemRepresentation.TypeEnum.PROPERTY)
    .sort((i1, i2) => ((i1.orderIndex || 0) > (i2.orderIndex || 0) ? 1 : -1));
  for (const [index, i] of sortedSteps.entries()) {
    if (i.orderIndex === orderIndex) {
      return index + 1;
    }
  }
  return undefined;
}

/**
 * finds the step index based on the orderIndex
 *
 * @param step the step to find the index of
 * @param steps all steps of the proposal
 */
export function stepIndex(
  item: ProposalLanguageItemRepresentation,
  items: ProposalLanguageItemRepresentation[]
): number | undefined {
  return _stepIndex(item.orderIndex, items);
}

/**
 * finds the step index based on the orderIndex (for forms)
 *
 * @param stepForm the stepForm to find the index of
 * @param stepForms all stepForms of the proposal
 */
export function stepFormIndex(
  stepForm: UntypedFormGroup,
  items: ProposalLanguageItemRepresentation[]
): number | undefined {
  if (!stepForm) {
    return undefined;
  }

  return _stepIndex(_orderIndexfromItemForm(stepForm, items), items);
}

export function _orderIndex(
  item:
    | ProposalLanguageStepRepresentation
    | RequestedPropertyRepresentation
    | RequestedPropertyDescriptionRepresentation
    | ProposalLanguageTextTemplateRepresentation
    | undefined,
  items:
    | (
        | ProposalLanguageItemWithPropertyRepresentation
        | ProposalLanguageItemWithTextTemplateRepresentation
        | ProposalLanguageItemWithStepRepresentation
      )[]
    | undefined
): number {
  return getItem(item, items)?.orderIndex ?? Infinity;
}

export function _orderIndexfromItemForm(
  itemForm: AbstractControl,
  items:
    | (
        | ProposalLanguageItemWithPropertyRepresentation
        | ProposalLanguageItemWithTextTemplateRepresentation
        | ProposalLanguageItemWithStepRepresentation
      )[]
    | undefined
): number {
  return _orderIndex(itemForm.value, items);
}

// /**
//  * finds the orderIndex form based on an item control
//  *
//  * @param control entity control
//  */
export function _itemOrderIndexForm(itemForm: AbstractControl): AbstractControl | null {
  return itemForm?.get('orderIndex');
}

// /**
//  * finds the orderIndex based on an item control
//  *
//  * @param control item control
//  */
export function _itemOrderIndex(itemForm: AbstractControl): number {
  return _itemOrderIndexForm(itemForm)?.value ?? -1;
}

/**
 * Sorts the steps according to their orderIndex
 *
 * @param steps array of Proposal steps to be sorted
 */
export function sortSteps(
  items: ProposalLanguageItemRepresentation[] | undefined,
  steps: ProposalLanguageStepRepresentation[] | ProposalLanguageStepRepresentation[] | undefined
): ProposalLanguageStepRepresentation[] | ProposalLanguageStepRepresentation[] {
  return [...(steps || [])].sort((s1, s2) =>
    (_orderIndex(s1, items) || 0) > (_orderIndex(s2, items) || 0) ? 1 : -1
  );
}

function _skippedSteps(
  language: ProposalLanguageRepresentation,
  skips: {stepId: string; skipsToId: string}[]
) {
  const sortedSteps = [...(language.steps || [])].sort((step1, step2) =>
    _orderIndex(step1, language.items) > _orderIndex(step2, language.items) ? 1 : -1
  );

  const allSkippedSteps: ProposalLanguageStepRepresentation[] = [];

  for (const skip of skips) {
    const sourceStepIndex = sortedSteps.findIndex((s) => s.id === skip.stepId);
    const destinationStepIndex = sortedSteps.findIndex((s) => s.id === skip.skipsToId);

    const skippedSteps = sortedSteps.slice(sourceStepIndex + 1, destinationStepIndex);

    for (const skippedStep of skippedSteps) {
      if (!allSkippedSteps.find((ss) => ss.id === skippedStep.id)) {
        allSkippedSteps.push(skippedStep);
      }
    }
  }

  return allSkippedSteps;
}

/**
 * finds all skips that happen within the form based on the current form value
 *
 * @param language the proposal language
 * @param stepsForm the stepsForm that contains all the proposal steps
 */
export function stepSkips(
  language: ProposalLanguageRepresentation,
  form: UntypedFormGroup
): {stepId: string; skipsToId: string}[] {
  if (!language) {
    return [];
  }

  const stepsForm = <UntypedFormArray | undefined>form?.get('steps');

  if (!stepsForm) {
    return [];
  }

  const requestedPropertyDescriptions = language.requestedPropertyDescriptions;

  const skips: {stepId: string; skipsToId: string}[] = [];
  const stepIds = stepsForm.controls.map((c) => c.get('id')?.value);

  const addToSkips = (stepId: string | undefined, skipsToId: string | undefined) => {
    if (
      stepId &&
      skipsToId &&
      !skips.find((s) => s.stepId === stepId && s.skipsToId === skipsToId) &&
      stepId !== skipsToId &&
      stepIds.includes(skipsToId)
    ) {
      skips.push({stepId: stepId, skipsToId});
    }
  };

  for (const item of language.items || []) {
    if (item.type !== ProposalLanguageItemRepresentation.TypeEnum.STEP) {
      continue;
    }

    const stepItem = <ProposalLanguageItemWithStepRepresentation>item;

    const requestedPropertiesForm = Object.entries(
      (<UntypedFormGroup>form.get('requestedProperties')).controls
    )
      .filter(([index]) => {
        return stepItem.items?.find((i) => getItemIndex(i) === index);
      })
      .map(([index, c]) => ({index, c}));

    for (const {index: requestedPropertyId, c: requestedPropertyForm} of requestedPropertiesForm) {
      const requestedPropertyDescription = requestedPropertyDescriptions?.find(
        (rpd) => rpd.requestedPropertyId === requestedPropertyId
      );

      const selectedPropertyTypeForm = <UntypedFormGroup>requestedPropertyForm.get('propertyType');
      const requestedPropertyTypeDescription =
        requestedPropertyDescription?.requestedPropertyTypeDescriptions?.find(
          (rptd) => rptd.type === selectedPropertyTypeForm.value
        );

      if (!requestedPropertyTypeDescription?.customFieldDescription?.valueLabels) {
        continue;
      }

      const propertiesForm = <UntypedFormGroup>requestedPropertyForm.get('properties');

      const selectedPropertyForm = (Object.entries(propertiesForm.controls).find(
        ([type]) => type === selectedPropertyTypeForm.value
      ) || [undefined, undefined])[1];

      const value = selectedPropertyForm?.get('value')?.value;

      if (!value) {
        continue;
      }

      const valueLabel = requestedPropertyTypeDescription.customFieldDescription.valueLabels.find(
        (cfvl) => cfvl.value === value
      );

      if (!valueLabel?.skipTo) {
        continue;
      }

      addToSkips(getItemIndex(item), valueLabel.skipTo);
    }
  }

  for (const stepForm of stepsForm.controls.sort((c) =>
    c.value.orderIndex > c.value.orderIndex ? -1 : 1
  )) {
    const idForm = stepForm.get('id');
    const hasAnswerBasedSkips = !!skips.find((skip) => skip.stepId === idForm?.value);

    const skippedSteps = _skippedSteps(language, skips);
    const isAlreadySkipped = !!skippedSteps.find((step) => step.id === idForm?.value);

    if (!isAlreadySkipped && !hasAnswerBasedSkips) {
      addToSkips(idForm?.value, stepForm.get('skipTo')?.value);
    }
  }

  return skips;
}

/**
 * finds all the steps that are skipped and returns them
 *
 * @param language the proposal language
 * @param stepsForm the stepsForm that contains all the proposal steps
 */
export function skippedSteps(
  language: ProposalLanguageRepresentation | undefined,
  form: UntypedFormGroup
): ProposalLanguageStepRepresentation[] {
  if (!language) {
    return [];
  }

  const skips = stepSkips(language, form);
  return _skippedSteps(language, skips);
}

/**
 * filters out all skipped step controls of the stepsForm
 * returns the remaining step controls
 *
 * @param language the proposal language
 * @param stepsForm the stepsForm where the controls will be filtered out
 */
export function activeStepForms(
  language: ProposalLanguageRepresentation | undefined,
  form: UntypedFormGroup,
  items:
    | (
        | ProposalLanguageItemWithPropertyRepresentation
        | ProposalLanguageItemWithTextTemplateRepresentation
        | ProposalLanguageItemWithStepRepresentation
      )[]
    | undefined
): UntypedFormGroup[] {
  if (!form || !language) {
    return [];
  }

  const stepsForm = <UntypedFormArray>form.get('steps');
  const skipped = skippedSteps(language, form);

  return (<UntypedFormGroup[]>stepsForm.controls || [])
    .filter((c) => {
      return !skipped.find((s) => s.id === c.get('id')?.value);
    })
    .sort((c1, c2) => {
      const orderIndex1 = _orderIndex(c1.value, items);
      const orderIndex2 = _orderIndex(c2.value, items);
      if (orderIndex1 === orderIndex2) {
        return 0;
      }
      return orderIndex1 > orderIndex2 ? 1 : -1;
    });
}

export function getStepRequestedPropertyIds(
  step: ProposalLanguageStepRepresentation | undefined,
  items:
    | (
        | ProposalLanguageItemWithPropertyRepresentation
        | ProposalLanguageItemWithTextTemplateRepresentation
        | ProposalLanguageItemWithStepRepresentation
      )[]
    | undefined
): string[] {
  const item = getItem(step, items);
  return getRequestedPropertyIds(item);
}

export function getRequestedPropertyIds(
  item:
    | ProposalLanguageItemWithTextTemplateRepresentation
    | ProposalLanguageItemWithStepRepresentation
    | undefined
): string[] {
  return <string[]>(item?.items || []).map((i) => getItemIndex(i)).filter((i) => !!i) || [];
}
