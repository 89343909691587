/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {WebhookEvent} from './webhookEvent';

export interface ProposalWebhookPayloadRepresentation {
  type?: ProposalWebhookPayloadRepresentation.TypeEnum;
  events?: Array<WebhookEvent>;
}
export namespace ProposalWebhookPayloadRepresentation {
  export type TypeEnum =
    | 'AGREEMENT_UPDATED_BY_OWNER'
    | 'AGREEMENT_UPDATED_BY_SUBSCRIBER'
    | 'AGREEMENT_NON_CONFIRMED_CREATED'
    | 'AGREEMENT_SUBSCRIBED'
    | 'AGREEMENT_UNSUBSCRIBED'
    | 'UNRECOGNIZED';
  export const TypeEnum = {
    AGREEMENT_UPDATED_BY_OWNER: 'AGREEMENT_UPDATED_BY_OWNER' as TypeEnum,
    AGREEMENT_UPDATED_BY_SUBSCRIBER: 'AGREEMENT_UPDATED_BY_SUBSCRIBER' as TypeEnum,
    AGREEMENT_NON_CONFIRMED_CREATED: 'AGREEMENT_NON_CONFIRMED_CREATED' as TypeEnum,
    AGREEMENT_SUBSCRIBED: 'AGREEMENT_SUBSCRIBED' as TypeEnum,
    AGREEMENT_UNSUBSCRIBED: 'AGREEMENT_UNSUBSCRIBED' as TypeEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as TypeEnum,
  };
}
