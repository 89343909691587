{
  "login": "/login",
  "register": "/register",
  "register_pending": "/register/pending",
  "register_invalid_token": "/register/invalid",
  "contacts": "/",
  "groups": "/groups",
  "groups_member_settings": "/settings/settings-member",
  "api_keys": "/settings/api-keys",
  "web_components": "/settings/web-components",
  "agreements": "/agreements",
  "search": "/search",
  "requests": "/requests",
  "requests_contactinfo": "/requests/:profileId/contact-info",
  "requests_settings": "/settings/requests",
  "notifications": "/notifications",
  "notifications_settings": "/settings/notifications",
  "notification_action": "/notification/:id",
  "account_settings": "/settings/account",
  "payment_settings": "/settings/payment",
  "payment_settings_subscriptions": "/settings/payment/subscriptions",
  "payment_settings_credits": "/settings/payment/credits",
  "payment_settings_billinginfo": "/settings/payment/billing-info",
  "payment_settings_invoices": "/settings/payment/invoices",
  "web_id": "/settings/web-id",
  "products_settings": "/settings/products",
  "organisation": "/organisation",
  "organisations": "/organisations",
  "proposals": "/proposals",
  "suggestions": "/search?type=SUGGESTIONS",
  "suggestions_settings": "/settings/suggestions",
  "settings": "/settings",
  "property_shared": "/property/:propertyId",
  "profile_username": "/:username*",
  "profile": "/profile/:id",
  "profile_edit": "/profile/:id/edit",
  "profile_contacts": "/profile/:id/contacts",
  "profile_groups": "/profile/:id/groups",
  "profile_followers": "/profile/:id/followers",
  "profile_visibility": "/profile/:id/visibility",
  "profile_proposals": "/profile/:id/proposals",
  "profile_agreements": "/profile/:id/agreements",
  "profile_forward": "/profile/:id/forward",
  "analytics": "/analytics",
  "me_profile_shortcut": "/me-profile",
  "me_edit_profile_visibility": "/me-profile/visibility/edit",
  "me_edit_profile_seo": "/me-profile/seo/edit",
  "me_edit_property": "/me-profile/:contextType/:propertyType/edit",
  "me_edit_property_visibility": "/me-profile/:contextType/:propertyType/visibility",
  "invitation_error": "/invitation/:code/error",
  "invitation_claim": "/invitation/:code/claim",
  "invitation_complete": "/invitation/:code/complete",
  "invitation_send": "/invitation/:code",
  "contact": "/contact/:id",
  "contact_forward": "/contact/:id/forward",
  "contact_edit": "/contact/:id/edit",
  "contact_edit_property": "/contact/:id/:contextType/:propertyType/edit",
  "contact_settings": "/contact/:id/settings",
  "group": "/group/:id",
  "group_followers": "/group/:id/followers",
  "group_settings": "/group/:id/settings",
  "group_forward": "/group/:id/forward",
  "edit_group_visibility": "/group/:id/visibility",
  "proposal": "/proposal/:id",
  "proposal_edit": "/proposal/:id/edit",
  "proposal_subscribe": "/proposal/:id/subscribe",
  "proposal_agreements": "/proposal/:id/agreements-data",
  "proposal_agreements_created": "/proposal/:id/agreements-created",
  "proposal_nca_create": "/proposal/:id/agreement/add-unconfirmed",
  "proposal_subscriber_agreements": "/proposal/:id/subscriber-agreements",
  "proposal_visibility_edit": "/proposal/:id/visibility",
  "privacy_analytics_settings": "/settings/analytics",
  "agreement": "/proposal/:proposalId/agreement/:agreementId",
  "agreement_history": "/proposal/:proposalId/agreement/:agreementId/history",
  "agreement_edit": "/proposal/:proposalId/agreement/:agreementId/edit",
  "external_agreement": "/proposal/agreement/external/:code",
  "external_agreement_history": "/proposal/agreement/external/:code/history",
  "external_agreement_verification": "/proposal/agreement/verification/:verificationCode",
  "non_confirmed_agreement": "/proposal/agreement/confirm/:code",
  "policy": "/policy",
  "duplicates": "/duplicates",
  "pages": "/pages",
  "page": "/page/:pageId",
  "page_edit": "/page/:pageId/edit",
  "page_content": "/page/:pageId/edit/content",
  "page_appearance": "/page/:pageId/edit/appearance",
  "page_settings": "/page/:pageId/edit/settings",
  "page_visibility_edit": "/page/:pageId/visibility",
  "pricing": "/pricing",
  "signing_orders": "/signing-orders",
  "signing_order": "/signing-order/:signingOrderId",
  "proposal_content": "/proposal/:proposalId/edit/content",
  "proposal_appearance": "/proposal/:proposalId/edit/appearance",
  "proposal_settings": "/proposal/:proposalId/edit/settings",
  "proposal_info": "/proposal/:proposalId/edit/info",
  "products": "/settings/products",
  "product_detail": "/settings/products/:stripeId"
}
