import {Locale} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {TranslateService} from '@aztrix/translate';
import {firstValueFrom, from, Observable} from 'rxjs';

import {ProposalPropertyTitlePipe} from '../pipes/proposal/proposal-property-title.pipe';
import {proposalPropertyDisplayValue$} from './property-functions';
import {getProposalProperty, getProposalRequestedPropertyType} from './proposal-functions';

// leave comment below (fixes build errors: https://github.com/ng-packagr/ng-packagr/issues/641)
// @dynamic
export class TemplateParser {
  static parse$(
    textTemplate: string | undefined,
    translate: TranslateService,
    proposal: ProposalRepresentation | undefined,
    language: ProposalLanguageRepresentation | undefined,
    locale: Locale,
    agreementProperties?: AgreementPropertyRepresentation[],
    keepButtons = false
  ): Observable<string | undefined> {
    return from(
      TemplateParser._parse$(
        textTemplate,
        translate,
        proposal,
        language,
        locale,
        agreementProperties ? agreementProperties : [],
        keepButtons
      )
    );
  }

  private static async _parse$(
    textTemplate: string | undefined,
    translate: TranslateService,
    proposal: ProposalRepresentation | undefined,
    language: ProposalLanguageRepresentation | undefined,
    locale: Locale,
    agreementProperties: AgreementPropertyRepresentation[],
    keepButtons = false
  ): Promise<string | undefined> {
    const parentNode = TemplateParser.parentNode(textTemplate);
    for (const templateVariableNode of TemplateParser.nodes(parentNode)) {
      const requestedPropertyId = TemplateParser._nodeRequestedPropertyId(templateVariableNode);

      if (!requestedPropertyId) {
        continue;
      }

      const agreementProperty = agreementProperties?.find((p) => {
        return p.requestedPropertyId === requestedPropertyId;
      });

      let displayValue: string | null | undefined;
      if (agreementProperty) {
        const proposalPropertyType = getProposalRequestedPropertyType(
          proposal,
          requestedPropertyId,
          agreementProperty.property?.type
        );
        displayValue = await firstValueFrom(
          proposalPropertyDisplayValue$(
            translate,
            agreementProperty,
            proposalPropertyType,
            locale,
            language
          )
        );
      }

      let result = '';
      if (displayValue) {
        result = displayValue;
      } else {
        const proposalProperty = getProposalProperty(
          <ProposalRepresentation>proposal,
          requestedPropertyId
        );
        if (proposalProperty) {
          const label = await firstValueFrom(
            ProposalPropertyTitlePipe.proposalPropertyTitle$(proposalProperty, language, translate)
          );
          result = `${label}`;
        } else {
          result = requestedPropertyId;
        }
      }

      if (keepButtons) {
        templateVariableNode.innerHTML = result;
      } else {
        templateVariableNode.outerHTML = result;
      }
    }
    return parentNode?.outerHTML;
  }

  static parentNode(textTemplateOrNode: string | Element | undefined): Element | undefined {
    if (typeof textTemplateOrNode === 'string') {
      const parser = new DOMParser();
      const document = parser.parseFromString(textTemplateOrNode, 'text/html');
      return document.body;
    } else {
      return textTemplateOrNode;
    }
  }

  static nodes(textTemplate: string | Element | undefined): Element[] {
    const parentNode = TemplateParser.parentNode(textTemplate);
    if (!parentNode) {
      return [];
    }
    return Array.from(parentNode.querySelectorAll('.ax-quill-template-variable'));
  }

  private static _nodeRequestedPropertyId(templateVariableNode: Element): string | undefined {
    return templateVariableNode.attributes.getNamedItem('data-index')?.value;
  }

  //  static requestedPropertyIds(textTemplate: string | undefined): string[] {
  //   return TemplateParser.nodes(textTemplate).map(
  //     (node) => TemplateParser._nodeRequestedPropertyId(node) || ''
  //   );
  // }
}
