import {Injectable} from '@angular/core';
import {
  AgreementRepresentation,
  ExternalAgreementRepresentation,
  ProposalsService,
  SubscribeRepresentation,
  UpdateAgreementRepresentation,
} from '@aztrix/sdk';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SubscribeService {
  constructor(private _proposalsApiService: ProposalsService) {}

  subscribe(
    data: UpdateAgreementRepresentation | SubscribeRepresentation,
    proposalId: string,
    agreementCode?: string,
    external = false
  ): Observable<AgreementRepresentation | ExternalAgreementRepresentation> {
    if (external) {
      return this.subscribeExternally(data, proposalId, agreementCode);
    } else {
      return this.subscribeInternally(data, proposalId, agreementCode);
    }
  }

  subscribeInternally(
    data: UpdateAgreementRepresentation | SubscribeRepresentation,
    proposalId: string,
    agreementCode?: string
  ): Observable<AgreementRepresentation> {
    if (agreementCode) {
      return this._proposalsApiService.subscribeWithAgreementCode(proposalId, agreementCode, data);
    } else {
      return this._proposalsApiService.subscribe(proposalId, data);
    }
  }

  subscribeExternally(
    data: UpdateAgreementRepresentation | SubscribeRepresentation,
    proposalId: string,
    agreementCode?: string
  ): Observable<ExternalAgreementRepresentation> {
    if (agreementCode) {
      return this._proposalsApiService.subscribeExternallyWithAgreementCode(
        proposalId,
        agreementCode,
        data
      );
    } else {
      return this._proposalsApiService.subscribeExternally(proposalId, data);
    }
  }
}
