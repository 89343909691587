/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {ProposalLanguageRepresentation} from './proposalLanguageRepresentation';
import {RequestedPropertyRepresentation} from './requestedPropertyRepresentation';

export interface ProposalRepresentation {
  id?: string;
  format?: ProposalRepresentation.FormatEnum;
  ownerId?: string;
  type?: ProposalRepresentation.TypeEnum;
  legalGround?: ProposalRepresentation.LegalGroundEnum;
  startTimestamp?: number;
  endTimestamp?: number;
  subscribed?: boolean;
  visibility?: ProposalRepresentation.VisibilityEnum;
  profileContext?: ProposalRepresentation.ProfileContextEnum;
  access?: ProposalRepresentation.AccessEnum;
  verification?: ProposalRepresentation.VerificationEnum;
  pending?: boolean;
  stopped?: boolean;
  retentionDuration?: number;
  product?: string;
  requestedProperties?: Array<RequestedPropertyRepresentation>;
  languages?: Array<ProposalLanguageRepresentation>;
  agreementCount?: number;
}
export namespace ProposalRepresentation {
  export type FormatEnum = 'MINI' | 'SUMMARY' | 'FULL' | 'ID';
  export const FormatEnum = {
    MINI: 'MINI' as FormatEnum,
    SUMMARY: 'SUMMARY' as FormatEnum,
    FULL: 'FULL' as FormatEnum,
    ID: 'ID' as FormatEnum,
  };
  export type TypeEnum = 'NONE' | 'NEWSLETTER' | 'REGISTRATION' | 'INVOICE' | 'CONTRACT' | 'REVIEW';
  export const TypeEnum = {
    NONE: 'NONE' as TypeEnum,
    NEWSLETTER: 'NEWSLETTER' as TypeEnum,
    REGISTRATION: 'REGISTRATION' as TypeEnum,
    INVOICE: 'INVOICE' as TypeEnum,
    CONTRACT: 'CONTRACT' as TypeEnum,
    REVIEW: 'REVIEW' as TypeEnum,
  };
  export type LegalGroundEnum =
    | 'CONSENT'
    | 'CONTRACT'
    | 'LEGITIMATE_INTEREST'
    | 'LEGAL_OBLIGATION'
    | 'PUBLIC_INTEREST';
  export const LegalGroundEnum = {
    CONSENT: 'CONSENT' as LegalGroundEnum,
    CONTRACT: 'CONTRACT' as LegalGroundEnum,
    LEGITIMATE_INTEREST: 'LEGITIMATE_INTEREST' as LegalGroundEnum,
    LEGAL_OBLIGATION: 'LEGAL_OBLIGATION' as LegalGroundEnum,
    PUBLIC_INTEREST: 'PUBLIC_INTEREST' as LegalGroundEnum,
  };
  export type VisibilityEnum = 'PUBLIC' | 'PRIVATE';
  export const VisibilityEnum = {
    PUBLIC: 'PUBLIC' as VisibilityEnum,
    PRIVATE: 'PRIVATE' as VisibilityEnum,
  };
  export type ProfileContextEnum = 'INDIVIDUAL' | 'ORGANISATION';
  export const ProfileContextEnum = {
    INDIVIDUAL: 'INDIVIDUAL' as ProfileContextEnum,
    ORGANISATION: 'ORGANISATION' as ProfileContextEnum,
  };
  export type AccessEnum = 'OPEN' | 'CLOSED';
  export const AccessEnum = {
    OPEN: 'OPEN' as AccessEnum,
    CLOSED: 'CLOSED' as AccessEnum,
  };
  export type VerificationEnum = 'NONE' | 'EMAIL' | 'IDENTITY' | 'MOBILE';
  export const VerificationEnum = {
    NONE: 'NONE' as VerificationEnum,
    EMAIL: 'EMAIL' as VerificationEnum,
    IDENTITY: 'IDENTITY' as VerificationEnum,
    MOBILE: 'MOBILE' as VerificationEnum,
  };
}
