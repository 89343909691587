import {Pipe, PipeTransform} from '@angular/core';
import {
  PropertyRepresentation,
  ProposalRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';

import {getProposalRequestedPropertyType} from '../../helpers/helpers-api';

@Pipe({name: 'proposalRequestedPropertyType'})
export class ProposalRequestedPropertyTypePipe implements PipeTransform {
  transform(
    proposal: ProposalRepresentation,
    requestedId: string | undefined,
    type: PropertyRepresentation.TypeEnum | undefined
  ): RequestedPropertyTypeRepresentation | undefined {
    return getProposalRequestedPropertyType(<ProposalRepresentation>proposal, requestedId, type);
  }
}
