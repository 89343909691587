/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProposalWebhookRepresentation {
  id?: string;
  proposalId?: string;
  url?: string;
  secret?: string;
  types?: Array<ProposalWebhookRepresentation.TypesEnum>;
}
export namespace ProposalWebhookRepresentation {
  export type TypesEnum =
    | 'AGREEMENT_UPDATED_BY_OWNER'
    | 'AGREEMENT_UPDATED_BY_SUBSCRIBER'
    | 'AGREEMENT_NON_CONFIRMED_CREATED'
    | 'AGREEMENT_SUBSCRIBED'
    | 'AGREEMENT_UNSUBSCRIBED'
    | 'UNRECOGNIZED';
  export const TypesEnum = {
    AGREEMENT_UPDATED_BY_OWNER: 'AGREEMENT_UPDATED_BY_OWNER' as TypesEnum,
    AGREEMENT_UPDATED_BY_SUBSCRIBER: 'AGREEMENT_UPDATED_BY_SUBSCRIBER' as TypesEnum,
    AGREEMENT_NON_CONFIRMED_CREATED: 'AGREEMENT_NON_CONFIRMED_CREATED' as TypesEnum,
    AGREEMENT_SUBSCRIBED: 'AGREEMENT_SUBSCRIBED' as TypesEnum,
    AGREEMENT_UNSUBSCRIBED: 'AGREEMENT_UNSUBSCRIBED' as TypesEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as TypesEnum,
  };
}
