import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {ForwardType, Profile, PropertyType} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  AgreementRepresentation,
  CustomFieldInfoRepresentation,
  CustomFieldValueLabelRepresentation,
  DocumentRepresentation,
  PageRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageItemRepresentation,
  ProposalLanguageItemWithPropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageItemWithTextTemplateRepresentation,
  ProposalLanguageRepresentation,
  ProposalLanguageStepRepresentation,
  ProposalLanguageTextTemplateRepresentation,
  ProposalRepresentation,
  RequestedPropertyDescriptionRepresentation,
  RequestedPropertyRepresentation,
  RequestedPropertyTypeDescriptionRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';

import {getLink} from '../metadata/links';
import {ROUTER_LINKS} from '../metadata/metadata';
import {unique} from '../util/array.util';
import {addQueryParametersToLink} from '../util/http.util';
import {isAgreement} from './agreement-functions';
import {_orderIndex, getStepRequestedPropertyIds, sortSteps} from './subscribe-step-functions';

export function isProposal(proposal: any): proposal is ProposalRepresentation {
  return Object.keys(ProposalRepresentation.TypeEnum).includes(proposal?.type);
}

export function languagePendingFilter(language: ProposalLanguageRepresentation): boolean {
  return language?.pending || false;
}

export function isActive(language: ProposalLanguageRepresentation): boolean {
  return !(language?.pending || false);
}

export function hasVerification(proposal: ProposalRepresentation | undefined): boolean {
  if (!proposal) {
    return false;
  }
  return (
    (proposal?.verification &&
      proposal?.verification !== ProposalRepresentation.VerificationEnum.NONE) ||
    false
  );
}

/**
 * Gets all the pending proposal languages and latest active languages for the provided languageCode
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}, {creationTimestamp: 1, pending: true}, {creationTimestamp: 2, pending: true}]
 * Result:
 * [{creationTimestamp: 1}, {creationTimestamp: 2, pending: true}]
 *
 * @param proposal the proposal to get the languages of
 * @param languageCode the languageCode to find languages for
 */
export function getLatestLanguages(
  proposal: ProposalRepresentation,
  languageCode?: string
): ProposalLanguageRepresentation[] {
  if (!proposal?.languages?.length) {
    return [];
  }

  const languageCodes = unique(proposal.languages.map((l) => l.languageCode));

  let languages: ProposalLanguageRepresentation[] = [];
  for (const lc of languageCodes) {
    const foundLanguages = (<ProposalLanguageRepresentation[]>proposal.languages).filter(
      (l) => l.languageCode === lc
    );
    const activeLanguages = foundLanguages.filter(isActive);
    const pendingLanguages = foundLanguages.filter(languagePendingFilter);

    languages = [
      ...languages,
      ...activeLanguages.filter(
        (language) =>
          !activeLanguages.find(
            (l) => (l.creationTimestamp || 0) > (language.creationTimestamp || 0)
          )
      ),
      ...pendingLanguages.filter(
        (language) =>
          !pendingLanguages.find(
            (l) => (l.creationTimestamp || 0) > (language.creationTimestamp || 0)
          )
      ),
    ];
  }

  if (languageCode) {
    return languages.filter((l) => l.languageCode === languageCode);
  }

  return languages;
}

/**
 * Gets the latest active language for the provided languageCode
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}, {creationTimestamp: 1, pending: true}]
 * Result:
 * {creationTimestamp: 1}
 *
 * @param proposal the proposal to get the language of
 * @param languageCode the languageCode to find a language for
 */
export function getLatestLanguage(
  proposal: ProposalRepresentation,
  languageCode?: string
): ProposalLanguageRepresentation | undefined {
  const languages = getLatestLanguages(proposal, languageCode);
  return (<ProposalLanguageRepresentation[]>languages)?.find(isActive);
}

/**
 * Gets the latest pending language for the provided languageCode
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}, {creationTimestamp: 1, pending: true}]
 * Result:
 * {creationTimestamp: 1, pending: true}
 *
 * @param proposal the proposal to get the language of
 * @param languageCode the languageCode to find a language for
 */
export function getPendingLanguage(
  proposal: ProposalRepresentation,
  languageCode?: string
): ProposalLanguageRepresentation | undefined {
  const languages = <ProposalLanguageRepresentation[]>getLatestLanguages(proposal, languageCode);
  return languages?.find(languagePendingFilter) || languages?.find(isActive);
}

/**
 * Gets the default language
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1, isDefault: true},
 * {creationTimestamp: 1, pending: true}]
 * Result:
 * {creationTimestamp: 1, isDefault: true}
 *
 * @param proposal the proposal to get the language of
 */
export function getDefaultLanguage(
  proposal: ProposalRepresentation
): ProposalLanguageRepresentation | undefined {
  if (!proposal || !proposal.languages?.length) {
    return undefined;
  }
  return (
    (<ProposalLanguageRepresentation[]>proposal.languages).find((l) => l.isDefault) ??
    proposal.languages[0]
  );
}

/**
 * Gets the latest language for the provided languageCode or the default language if there is none
 *
 * @param proposal the proposal to get the language of
 * @param languageCode the languageCode to find a language for
 */
export function getLatestOrDefaultLanguage(
  proposal: ProposalRepresentation | undefined,
  languageCode?: string
): ProposalLanguageRepresentation | undefined {
  if (!proposal) {
    return undefined;
  }
  const language = getLatestLanguage(proposal, languageCode);
  if (!language) {
    return getDefaultLanguage(proposal);
  }
  return language;
}

/**
 * Gets the latest language for the provided languageCode or the default language if there is none
 *
 * @param languages the languages to search in
 * @param languageCode the languageCode to find a language for
 * @param timestamp the timestamp to find a language for
 */
export function getLanguageForTimestamp(
  languages: ProposalLanguageRepresentation[],
  languageCode: string,
  timestamp: number | undefined
): ProposalLanguageRepresentation {
  const language = languages
    .filter(isActive)
    .filter((language) => languageCode === language.languageCode)
    .sort((a, b) =>
      a.creationTimestamp === b.creationTimestamp
        ? 0
        : (a.creationTimestamp ?? 0) > (b.creationTimestamp ?? 0)
          ? -1
          : 1
    )
    .find((language) => (language.creationTimestamp ?? 0) < (timestamp ?? Infinity));
  return language || languages[0];
}

export function getPropertyDescription(
  language: ProposalLanguageRepresentation | undefined,
  propertyId: string | undefined
): RequestedPropertyDescriptionRepresentation | undefined {
  if (!language || !language.requestedPropertyDescriptions) {
    return undefined;
  }
  return (<ProposalLanguageRepresentation>language).requestedPropertyDescriptions?.find(
    (p) => p.requestedPropertyId === propertyId
  );
}

export function getPropertyTypeDescription(
  language: ProposalLanguageRepresentation | undefined,
  requestId: string | undefined,
  propertyType: PropertyType | undefined
): RequestedPropertyTypeDescriptionRepresentation | undefined {
  if (!language || !language.requestedPropertyDescriptions) {
    return undefined;
  }
  const propertyDescription = getPropertyDescription(language, requestId);
  return propertyDescription?.requestedPropertyTypeDescriptions?.find(
    (pt) => pt.type === propertyType
  );
}

export function getPropertyTypeDescriptions(
  property: RequestedPropertyRepresentation,
  language: ProposalLanguageRepresentation
): RequestedPropertyTypeDescriptionRepresentation[] {
  if (!property || !property.requestedPropertyTypes) {
    return [];
  }
  return <RequestedPropertyTypeDescriptionRepresentation[]>(
    property.requestedPropertyTypes
      .map((pi) => getPropertyTypeDescription(language, property.requestedPropertyId, pi.type))
      .filter((d) => d?.type)
  );
}

export function isOwner(
  subject: ProposalRepresentation | AgreementRepresentation | PageRepresentation | undefined,
  profile?: Profile | ProfileRepresentation
): boolean {
  const ownerId = isAgreement(subject)
    ? subject.proposalOwnerId
    : isProposal(subject)
      ? (<ProposalRepresentation>subject).ownerId
      : (<PageRepresentation>subject).ownerId;
  return !!ownerId && !!profile && [profile.id, profile.targetId].includes(ownerId);
}

export function canAddAgreement(
  proposal: ProposalRepresentation,
  profile?: Profile | ProfileRepresentation,
  agreement?: AgreementRepresentation
): boolean {
  if (
    profile &&
    (isOwner(proposal, profile) || profile?.profileContext !== proposal?.profileContext)
  ) {
    return false;
  }

  if (proposal?.access === ProposalRepresentation.AccessEnum.CLOSED) {
    return !proposal?.stopped && agreement?.subscriberId === profile?.id;
  }

  return !proposal?.stopped && proposal?.access === ProposalRepresentation.AccessEnum.OPEN;
}

export function proposalTitle(
  proposal: ProposalRepresentation,
  languageCode = 'en'
): string | undefined {
  let language = getPendingLanguage(proposal, languageCode);
  if (!language && proposal.languages?.length) {
    const defaultLanguage = (<ProposalLanguageRepresentation[]>proposal.languages).find(
      (l) => l.isDefault
    );
    if (defaultLanguage) {
      language = getPendingLanguage(proposal, defaultLanguage.languageCode);
    } else {
      language = proposal.languages[0];
    }
  }
  return language?.name ?? undefined;
}

export function getProposalPropertyByProposalProperties(
  properties: RequestedPropertyRepresentation[],
  requestedPropertyId: string | undefined
): RequestedPropertyRepresentation | undefined {
  if (!properties || !properties.length) {
    return undefined;
  }
  return properties.find((property) => property.requestedPropertyId === requestedPropertyId);
}

export function getFirstProposalProperty(
  proposal: ProposalRepresentation,
  propertyType: PropertyRepresentation.TypeEnum
): RequestedPropertyRepresentation | undefined {
  if (!proposal) {
    return undefined;
  }
  return proposal.requestedProperties?.find(
    (property) =>
      property.requestedPropertyTypes?.find(
        (requestedPropertyType) => requestedPropertyType.type === propertyType
      )
  );
}

export function getProposalProperty(
  proposal: ProposalRepresentation | undefined,
  requestedPropertyId: string | undefined
): RequestedPropertyRepresentation | undefined {
  if (!proposal) {
    return undefined;
  }

  return getProposalPropertyByProposalProperties(
    proposal?.requestedProperties || [],
    requestedPropertyId
  );
}

export function getProposalRequestedPropertyType(
  proposal: ProposalRepresentation | undefined,
  requestedPropertyId: string | undefined,
  propertyType?: RequestedPropertyTypeRepresentation.TypeEnum | undefined
): RequestedPropertyTypeRepresentation | undefined {
  if (!requestedPropertyId) {
    return undefined;
  }
  return getProposalPropertyType(getProposalProperty(proposal, requestedPropertyId), propertyType);
}

export function getProposalPropertyType(
  proposalProperty?: RequestedPropertyRepresentation,
  propertyType?: RequestedPropertyTypeRepresentation.TypeEnum
): RequestedPropertyTypeRepresentation | undefined {
  return proposalProperty?.requestedPropertyTypes?.find((rpt) => rpt.type === propertyType);
}

export function sortProposalProperties(
  language: ProposalLanguageRepresentation,
  properties: RequestedPropertyRepresentation[]
): RequestedPropertyRepresentation[] {
  return [...properties].sort((p1, p2) =>
    (getItem(p1, language.items)?.orderIndex || 0) < (getItem(p2, language.items)?.orderIndex || 0)
      ? -1
      : 1
  );
}

export function isRequiredProperty(
  language: ProposalLanguageRepresentation | undefined,
  requestedPropertyId: string | undefined
): boolean {
  if (!language) {
    return true;
  }
  const proposalPropertyDescription = getPropertyDescription(language, requestedPropertyId);
  return !!proposalPropertyDescription?.required;
}

export function isReadonlyProperty(
  language: ProposalLanguageRepresentation | undefined,
  requestedPropertyId: string | undefined
): boolean {
  if (!language) {
    return true;
  }
  const proposalPropertyDescription = getPropertyDescription(language, requestedPropertyId);
  return !!proposalPropertyDescription?.readonly;
}

export function sortAgreementProperties<T extends AgreementPropertyRepresentation>(
  proposal: ProposalRepresentation | undefined,
  language: ProposalLanguageRepresentation | undefined,
  agreementProperties: T[]
): T[] {
  if (!proposal || !language) {
    return [];
  }
  return [...agreementProperties].sort((p1, p2) => {
    const property1 = getProposalProperty(proposal, p1.requestedPropertyId);
    const property2 = getProposalProperty(proposal, p2.requestedPropertyId);

    const item1 = getItem(property1, language.items);
    const item2 = getItem(property2, language.items);

    return (item1?.orderIndex || 0) < (item2?.orderIndex || 0) ? -1 : 1;
  });
}

export function getProposalSteps(
  proposal: ProposalRepresentation,
  language: ProposalLanguageRepresentation
): ProposalLanguageStepRepresentation[] | ProposalLanguageStepRepresentation[] {
  if (!proposal?.requestedProperties?.length || !language) {
    return [
      {
        id: '1',
        title: undefined,
        description: undefined,
        skipTo: '',
      },
    ];
  }

  if (language.steps?.length) {
    return sortSteps(language.items, [...(language.steps || [])]);
  } else {
    return [
      {
        id: '1',
        title: undefined,
        description: undefined,
        skipTo: '',
      },
    ];
  }
}

/**
 * Returns all properties from a proposal that are able to configure a skip in the subscribe
 *
 * @param proposal the proposal to get the language of
 */
export function proposalPropertiesSkipsConfigurable(
  proposal: ProposalRepresentation
): RequestedPropertyRepresentation[] {
  const propertiesAbleToSkip: RequestedPropertyRepresentation[] = [];
  (proposal?.requestedProperties || []).map((proposalProperty) => {
    for (const proposalPropertyType of proposalProperty.requestedPropertyTypes || []) {
      if (
        proposalPropertyType.type === PropertyRepresentation.TypeEnum.CUSTOM &&
        (proposalPropertyType.customFieldInfo?.type ===
          CustomFieldInfoRepresentation.TypeEnum.RADIO ||
          proposalPropertyType.customFieldInfo?.type ===
            CustomFieldInfoRepresentation.TypeEnum.DROPDOWN)
      ) {
        propertiesAbleToSkip.push(proposalProperty);
      }
    }
  });
  return propertiesAbleToSkip;
}

/**
 * Checks if a proposal language has multiple skips configured in the same step
 *
 * @param proposal the proposal to get the language of
 * @param proposalLanguage the proposal language used to check if it has multiple skips in the same step configured
 */
export function proposalHasSkipWarning(
  proposal: ProposalRepresentation,
  proposalLanguage?: ProposalLanguageRepresentation
): string[] {
  if (!proposalLanguage) {
    return [];
  }

  const propertiesAbleToSkip = proposalPropertiesSkipsConfigurable(proposal);
  const allPropertyDescriptions = [];
  for (const propertyAbleToSkip of propertiesAbleToSkip) {
    const result = proposalLanguage.requestedPropertyDescriptions?.find(
      (p) => p.requestedPropertyId === propertyAbleToSkip.requestedPropertyId
    );
    if (result) {
      allPropertyDescriptions.push(result);
    }
  }

  const propertyDescriptionsWithSkipsConfigured: RequestedPropertyDescriptionRepresentation[] = [];

  for (const propertyDescription of allPropertyDescriptions) {
    if (propertyDescription) {
      for (const requestedPropertyTypeDescription of propertyDescription.requestedPropertyTypeDescriptions ||
        []) {
        if (requestedPropertyTypeDescription) {
          for (const valueLabel of requestedPropertyTypeDescription.customFieldDescription
            ?.valueLabels || []) {
            if (valueLabel.skipTo && valueLabel.skipTo !== '') {
              propertyDescriptionsWithSkipsConfigured.push(propertyDescription);
              break;
            }
          }
        }
      }
    }
  }

  const stepIdsWithWarning: string[] = [];

  for (const step of proposalLanguage.steps || []) {
    const ids = getStepRequestedPropertyIds(step, proposalLanguage.items);
    if (
      propertyDescriptionsWithSkipsConfigured.filter(
        (t) => ids?.includes(t.requestedPropertyId || '')
      ).length > 1
    ) {
      stepIdsWithWarning.push(step.id || '');
    }
  }

  return stepIdsWithWarning;
}

/**
 * Checks if a proposal language has backSkips configured
 *
 * @param proposal the proposal to get the language of
 * @param proposalLanguage the proposal language used to check if it has backskips configured
 */
export function proposalHasBackSkipErrors(
  proposal: ProposalRepresentation,
  proposalLanguage: ProposalLanguageRepresentation
): boolean {
  const propertiesAbleToSkip = proposalPropertiesSkipsConfigurable(proposal);

  const proposalSteps = proposalLanguage?.steps || [];

  for (const property of propertiesAbleToSkip) {
    const propertyDescription = proposalLanguage.requestedPropertyDescriptions?.find(
      (d) => d.requestedPropertyId === property.requestedPropertyId
    );
    const valueLabelsToCheck =
      propertyDescription?.requestedPropertyTypeDescriptions?.[0]?.customFieldDescription?.valueLabels?.filter(
        (vl) => vl.skipTo !== ''
      ) || [];
    for (const vl of valueLabelsToCheck) {
      if (vl.skipTo) {
        const currentStep = proposalSteps.find(
          (s) =>
            getStepRequestedPropertyIds(s, proposalLanguage.items)?.includes(
              property.requestedPropertyId || ''
            )
        );
        const stepToSkipTo = proposalSteps.find((s) => s.id === vl.skipTo);

        if (
          _orderIndex(stepToSkipTo, proposalLanguage.items) <=
          _orderIndex(currentStep, proposalLanguage.items)
        ) {
          return true;
        }
      }
    }
  }

  for (const step of proposalSteps) {
    if (
      proposalSteps.find(
        (s) =>
          s.id === step.skipTo &&
          _orderIndex(s, proposalLanguage.items) <= _orderIndex(step, proposalLanguage.items)
      )
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Checks if the proposal has skips configured in any language but pending
 *
 * @param proposal the proposal to get the language of
 */
export function proposalHasSkips(proposal: ProposalRepresentation): boolean {
  const languages = proposal.languages?.filter((language) => !languagePendingFilter(language));
  const hasStepSkips = !!languages?.find((language) => stepsHaveStepSkips(language.steps || []));
  const hasAnswerSkips = !!languages?.find((language) =>
    languageHasAnswerSkips(language.requestedPropertyDescriptions || [])
  );
  if (hasStepSkips) {
    return true;
  }
  if (hasAnswerSkips) {
    return true;
  }
  return false;
}

/**
 * Checks if one or more steps have a step based skipTo value
 *
 * @param steps steps to check
 */
export function stepsHaveStepSkips(steps: ProposalLanguageStepRepresentation[]): boolean {
  return !!steps.find((step) => step.skipTo && step.skipTo !== '');
}

/**
 * Checks if the requestedPropertyDescriptions has a skipTo value configured
 *
 * @param requestedPropertyDescriptions requestedPropertyDescriptions to check
 */
export function languageHasAnswerSkips(
  requestedPropertyDescriptions: RequestedPropertyDescriptionRepresentation[]
): boolean {
  const valueLabels = requestedPropertyDescriptions.flatMap(customFieldValueLabels);
  return !!valueLabels.find((valueLabel) => valueLabel?.skipTo && valueLabel.skipTo !== '');
}

// TODO: use when there are signable documents
// /**
//  * Checks if the proposal has signable dociments in any language
//  *
//  * @param proposal proposal to check
//  */
//  export function proposalHasSignableDocuments(proposal: ProposalRepresentation): boolean {
//   return !!proposal.languages.find(languageHasSignableDocuments);
// }

// /**
//  * Checks if the language has signable documents
//  *
//  * @param language steps to check
//  */
//  export function languageHasSignableDocuments(language: ProposalLanguage): boolean {
//    if(!language?.documents) {
//     return false
//    }
//   return !!language.documents.find((document)=> document?.signingRequired)
// }

export function pendingDocument(
  activeProposalDocument: DocumentRepresentation,
  pendingProposalDocument: DocumentRepresentation
): boolean {
  return (
    !activeProposalDocument ||
    activeProposalDocument.name !== pendingProposalDocument.name ||
    activeProposalDocument.type !== pendingProposalDocument.type ||
    activeProposalDocument.value !== pendingProposalDocument.value ||
    activeProposalDocument.checkboxLabel !== pendingProposalDocument.checkboxLabel ||
    activeProposalDocument.filename !== pendingProposalDocument.filename ||
    (activeProposalDocument.description || '') !== (pendingProposalDocument.description || '') ||
    activeProposalDocument.orderIndex !== pendingProposalDocument.orderIndex
  );
}

export function customFieldValueLabels(
  requestedPropertyDescription: RequestedPropertyDescriptionRepresentation
): CustomFieldValueLabelRepresentation[] {
  return <CustomFieldValueLabelRepresentation[]>(
    requestedPropertyDescription.requestedPropertyTypeDescriptions
      ?.flatMap(
        (requestedPropertyTypeDescription) =>
          requestedPropertyTypeDescription.customFieldDescription?.valueLabels
      )
      .filter((valueLabel) => !!valueLabel)
  );
}

export function proposalForwardLink(
  proposal: ProposalRepresentation,
  baseUrl: string,
  forwardOption?: ForwardType,
  completeLink = true
): string | undefined {
  if (!proposal) {
    return undefined;
  }
  const link = completeLink
    ? `${baseUrl || ''}${proposalSubscriptionLink(proposal)}`
    : proposalSubscriptionLink(proposal) + '/forward';
  return forwardOption ? addQueryParametersToLink(link, {forwardType: forwardOption}) : link;
}

export function proposalSubscriptionLink(proposal: ProposalRepresentation): string {
  return getLink(ROUTER_LINKS.proposal_subscribe, {id: proposal.id});
}

export function getItemIndex(
  item:
    | ProposalLanguageItemWithPropertyRepresentation
    | ProposalLanguageItemWithTextTemplateRepresentation
    | ProposalLanguageItemWithStepRepresentation
    | undefined
): string | undefined {
  if (!item) {
    return undefined;
  } else if ('requestedPropertyId' in item) {
    return item.requestedPropertyId;
  } else if ('textTemplateId' in item) {
    return item.textTemplateId;
  } else if ('stepId' in item) {
    return item.stepId;
  } else {
    return (<any>item).id;
  }
}

export function getItem(
  subject:
    | ProposalLanguageStepRepresentation
    | RequestedPropertyRepresentation
    | RequestedPropertyDescriptionRepresentation
    | ProposalLanguageTextTemplateRepresentation
    | undefined,
  items:
    | (
        | ProposalLanguageItemWithPropertyRepresentation
        | ProposalLanguageItemWithTextTemplateRepresentation
        | ProposalLanguageItemWithStepRepresentation
      )[]
    | undefined
):
  | ProposalLanguageItemWithPropertyRepresentation
  | ProposalLanguageItemWithTextTemplateRepresentation
  | ProposalLanguageItemWithStepRepresentation
  | undefined {
  if (!subject) {
    return undefined;
  }
  let id: string | undefined;
  if ('id' in subject) {
    id = subject.id;
  } else if ('requestedPropertyId' in subject) {
    id = subject.requestedPropertyId;
  }
  return (items || []).find((i) => getItemIndex(i) === id);
}

export function getValueForItem(
  item:
    | ProposalLanguageItemWithPropertyRepresentation
    | ProposalLanguageItemWithTextTemplateRepresentation
    | ProposalLanguageItemWithStepRepresentation
    | undefined,
  language: ProposalLanguageRepresentation
):
  | ProposalLanguageStepRepresentation
  | RequestedPropertyDescriptionRepresentation
  | ProposalLanguageTextTemplateRepresentation
  | undefined {
  if (!item) {
    return undefined;
  } else if ('requestedPropertyId' in item) {
    return language.requestedPropertyDescriptions?.find(
      (p) => p.requestedPropertyId === item.requestedPropertyId
    );
  } else if ('textTemplateId' in item) {
    return language.textTemplates?.find((p) => p.id === item.textTemplateId);
  } else if ('stepId' in item) {
    return language.steps?.find((p) => p.id === item.stepId);
  } else {
    return undefined;
  }
}

export function getValueFormForItem(
  item:
    | ProposalLanguageItemWithPropertyRepresentation
    | ProposalLanguageItemWithTextTemplateRepresentation
    | ProposalLanguageItemWithStepRepresentation
    | undefined,
  languageForm: UntypedFormGroup
): AbstractControl<any> | undefined {
  if (!item) {
    return undefined;
  } else if ('requestedPropertyId' in item) {
    return (<UntypedFormArray>languageForm.get('requestedPropertyDescriptions')).controls?.find(
      (c) => c.get('requestedPropertyId')?.value === item.requestedPropertyId
    );
  } else if ('textTemplateId' in item) {
    return (<UntypedFormArray>languageForm.get('textTemplates')).controls?.find(
      (c) => c.get('id')?.value === item.textTemplateId
    );
  } else if ('stepId' in item) {
    return (<UntypedFormArray>languageForm.get('steps')).controls?.find(
      (c) => c.get('id')?.value === item.stepId
    );
  } else {
    return undefined;
  }
}

export function getItemsWithNestedItems(
  items: ProposalLanguageItemWithTextTemplateRepresentation[]
): (
  | ProposalLanguageItemWithTextTemplateRepresentation
  | ProposalLanguageItemWithPropertyRepresentation
  | undefined
)[] {
  const itemsAndNested: (
    | ProposalLanguageItemWithTextTemplateRepresentation
    | ProposalLanguageItemWithPropertyRepresentation
    | undefined
  )[] = [...items, ...items.flatMap((item) => item.items)]
    .filter((i) => i)
    .filter((item) => item?.type === ProposalLanguageItemRepresentation.TypeEnum.TEMPLATE);

  return itemsAndNested?.filter(
    (item) => item?.type === ProposalLanguageItemRepresentation.TypeEnum.TEMPLATE
  );
}
