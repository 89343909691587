import {Property} from '../property/property';
import {SubjectFormat} from '../subject/subject-format';

export interface Agreement {
  id: string;
  proposalId: string;
  proposalOwnerId?: string;
  subscriberId: string;
  format?: SubjectFormat;
  startTimestamp: number;
  active?: boolean;
  expirationTimestamp: number;
  modificationTimestamp?: number;
  agreementCode?: string;
  deletedByOwner?: boolean;
  agreementData: AgreementData;
  agreementMetadata?: AgreementMetadata[];
  subscriberMetadata?: AgreementMetadata[];
  verified: boolean;
  verificationValue?: string;
  verificationTimestamp?: number;
}

export interface StaticAgreement {
  id: string;
  organisationId: string;
  organisationName: string;
  title: string;
  description: string;
  proposalType: string;
  startTimestamp: number;
  endTimestamp: number;
  clientNumber: string;
  note: string;
}

export interface AgreementMetadata {
  key: string;
  value: string;
}

export interface AgreementData {
  startTimestamp: number;
  endTimestamp?: number;
  confirmed: boolean;
  language?: string;
  createdBy?: 'OWNER' | 'SUBSCRIBER';
  agreementProperties: AgreementProperty[];
  agreementDocuments?: AgreementDocument[];
  ip?: string;
  userAgent?: string;
}

export interface AgreementProperty {
  requestedPropertyId: string;
  property: Property;
}

export interface AgreementDocument {
  proposalDocumentId: string;
  value: string;
  signed: boolean;
  signatureTimestamp?: number;
}

export interface IntermediateAgreementData {
  agreementProperties: AgreementProperty[];
  language: string;
  documents?: AgreementDocument[];
  code?: string;
  creationTimestamp?: number;
}

export enum AgreementState {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  EXPIRED = 'EXPIRED',
}

export enum AgreementVerificationType {
  NONE = 'NONE',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  IDENTITY = 'IDENTITY',
}
