import {
  AbstractControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AgreementDocument,
  AgreementVerificationType,
  MutableProperty,
  Profile,
} from '@aztrix/models';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  AgreementRepresentation,
  CustomFieldInfoRepresentation,
  IntermediateAgreementDataRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalLanguageStepRepresentation,
  ProposalLanguageTextTemplateRepresentation,
  ProposalRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';

import {CustomValidators, isValidUrl} from '../util/validator.util';
import {getPropertiesOfType} from './context-functions';
import {DocumentsForm} from './document-form';
import {PropertyFormControl} from './property-form-control';
import {dummyPropertyForType, hasEqualValue, hasValue, isSubPropertyOf} from './property-functions';
import {
  getPropertyTypeDescription,
  isReadonlyProperty,
  isRequiredProperty,
} from './proposal-functions';
import {sortSteps} from './subscribe-step-functions';

export declare class AddPropertyToProfile {
  property: PropertyRepresentation;
  profile: ProfileRepresentation;
  didCreate: (property: PropertyRepresentation) => void;
}

export class ProposalValidators {
  static propertyRequired(
    group: AbstractControl,
    requestedPropertyId: string | undefined
  ): ValidatorFn {
    const propertyRequiredFn = (control: AbstractControl): ValidationErrors | null => {
      const groupType = group.get('propertyType')?.value;
      const parent = control?.parent;
      const controlType = parent?.value?.type;

      // property
      if (groupType && (groupType === controlType || isSubPropertyOf(groupType, controlType))) {
        if (CustomValidators.propertyRequired(control)) {
          if (hasValue(parent?.value)) {
            return CustomValidators.propertyValid(control);
          }
          return {propertyRequired: {type: groupType, requestedPropertyId}};
        } else if (CustomValidators.propertyValid(control)) {
          return CustomValidators.propertyValid(control);
        } else {
          return null;
        }
      }

      return null;
    };

    return propertyRequiredFn;
  }

  static propertyOptional(group: AbstractControl): ValidatorFn {
    const propertyOptionalFn = (control: AbstractControl): ValidationErrors | null => {
      const groupType = group.get('propertyType')?.value;
      const controlType = control.parent?.get('type')?.value;

      const propertiesValue = group.get('properties')?.value;
      const isOrProperty = Object.values<MutableProperty>(propertiesValue)?.length > 1;

      if (
        groupType &&
        (groupType === controlType || isSubPropertyOf(groupType, controlType)) &&
        (control.value || isOrProperty)
      ) {
        let errors: ValidationErrors | null = null;
        if (control) {
          errors =
            Validators.compose([
              isOrProperty ? CustomValidators.propertyRequired : null,
              CustomValidators.propertyValid,
            ])?.(control) || null;
        }

        if (errors?.propertyRequired) {
          errors.propertyOptionalSelected = {
            type: errors.propertyRequired.type,
          };
          delete errors.propertyRequired;
        }

        return errors;
      }
      return null;
    };

    return propertyOptionalFn;
  }

  static propertyRequiredTrue(
    group: AbstractControl,
    requestedPropertyId: string | undefined
  ): ValidatorFn {
    const propertyRequiredTrueFn = (control: AbstractControl): ValidationErrors | null => {
      const groupType = group.get('propertyType')?.value;

      if (CustomValidators.propertyRequiredTrue()(control)) {
        return {propertyRequired: {type: groupType, requestedPropertyId}};
      }

      return null;
    };

    return propertyRequiredTrueFn;
  }

  static maxValuesValidator(group: UntypedFormGroup, max: number): ValidatorFn {
    const maxValuesValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const {propertyType} = group.value;

      if (propertyType && propertyType === control.value?.type) {
        return CustomValidators.maxValuesValidator(max)(control);
      }

      return null;
    };

    return maxValuesValidatorFn;
  }

  static propertyTypeRequired(control: AbstractControl): ValidationErrors | null {
    if (Validators.required(control)) {
      return {typeRequired: true};
    } else {
      return null;
    }
  }

  static customPropertyUrlValidator(
    group: AbstractControl,
    requestedPropertyId: string | undefined
  ): ValidatorFn {
    const customPropertyUrlValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const groupType = group.get('propertyType')?.value;

      if (!control.value) {
        return null;
      }

      if (!isValidUrl(control.value)) {
        return {propertyInvalid: {type: groupType, requestedPropertyId}};
      }

      return null;
    };

    return customPropertyUrlValidatorFn;
  }
}

// @dynamic
export class Subscribe {
  static createForm(
    proposal: ProposalRepresentation | undefined,
    isExternal = false,
    proposalLanguage?: ProposalLanguageRepresentation,
    agreement?: AgreementRepresentation,
    myProfile?: Profile | ProfileRepresentation,
    verification = true,
    showDocuments = true
  ): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    const stepsForm = new UntypedFormArray([]);
    const isOwner = Subscribe._isOwner(proposal, myProfile);
    const isVerifyProposal =
      proposal?.verification && proposal?.verification !== AgreementVerificationType.NONE;

    if (proposal?.requestedProperties && proposalLanguage) {
      Subscribe._createProposalStepsForm(stepsForm, proposalLanguage);

      form.addControl('steps', stepsForm);

      const properties = proposal.requestedProperties.filter((property) => {
        return !!property.requestedPropertyTypes?.find(
          (type) =>
            !!getPropertyTypeDescription(proposalLanguage, property.requestedPropertyId, type.type)
        );
      });

      const propertiesForm = new UntypedFormGroup({});
      for (const proposalProperty of properties) {
        Subscribe.createProposalPropertyForm(
          propertiesForm,
          proposalLanguage,
          agreement,
          <RequestedPropertyRepresentation>proposalProperty,
          isOwner,
          myProfile
        );
      }

      form.addControl('requestedProperties', propertiesForm);
    }

    if (proposalLanguage) {
      const textTemplatesForm = new UntypedFormArray([]);
      for (const textTemplate of proposalLanguage.textTemplates || []) {
        Subscribe._createProposalTextTemplateForm(textTemplatesForm, textTemplate);
      }

      form.addControl('textTemplates', textTemplatesForm);
    }

    if (
      proposal &&
      verification &&
      ((!isExternal && !isOwner) || isExternal) &&
      !agreement?.verified &&
      isVerifyProposal &&
      proposal?.verification !== AgreementVerificationType.IDENTITY
    ) {
      form.addControl('verification', Subscribe.createVerificationForm(proposal.verification));
    }

    let documentsForm = new UntypedFormArray([]);
    if (showDocuments && proposalLanguage) {
      documentsForm = DocumentsForm.createDocumentForm(
        proposalLanguage.documents ? proposalLanguage.documents : []
      );
    }
    form.addControl('documents', documentsForm);

    if (proposalLanguage?.askToSendEmail && isExternal) {
      form.addControl('sendToEmail', Subscribe.createSendToEmailForm());
    }

    return form;
  }

  static agreementProperties(
    proposalProperty: RequestedPropertyRepresentation | undefined,
    agreement?: AgreementRepresentation,
    myProfile?: Profile | ProfileRepresentation,
    all = false
  ): PropertyRepresentation[] {
    if (!proposalProperty) {
      return [];
    }

    const agreementProperties: PropertyRepresentation[] = [];
    for (const type of (proposalProperty.requestedPropertyTypes || []).map((pi) => pi.type)) {
      const properties: PropertyRepresentation[] = [];
      let agreementProperty: AgreementPropertyRepresentation | undefined = undefined;

      if (agreement?.agreementData?.agreementProperties?.length) {
        const foundAgreementProperty = agreement.agreementData.agreementProperties.find(
          (p) => p.requestedPropertyId === proposalProperty.requestedPropertyId
        );
        if (foundAgreementProperty) {
          agreementProperty = foundAgreementProperty;
        }
      }

      if (agreementProperty?.property && type === agreementProperty.property?.type) {
        properties.push(agreementProperty.property);
      }

      const profileProperties = <PropertyRepresentation[]>getPropertiesOfType(myProfile, type);
      const equalProperty = profileProperties.find((prop) =>
        hasEqualValue(prop, agreementProperty?.property)
      );
      if (equalProperty && profileProperties.includes(equalProperty)) {
        profileProperties.splice(properties.indexOf(equalProperty), 1);
        profileProperties.unshift(equalProperty);
      }

      properties.push(...profileProperties);

      if (!properties.length && type) {
        properties.push(<PropertyRepresentation>dummyPropertyForType(type));
      }

      if (all) {
        agreementProperties.push(...properties);
      } else {
        if (properties[0]) {
          agreementProperties.push(properties[0]);
        }
      }
    }

    return agreementProperties;
  }

  /**
   * Fills the form controls with the related agreement properties
   * A form control is only filled when it is pristine
   *
   * @param form the form to be prefilled
   * @param agreementProperties the properties to fill it with
   */
  static prefillForm(
    form: UntypedFormGroup | undefined,
    agreementProperties: AgreementPropertyRepresentation[] | undefined
  ): void {
    if (!agreementProperties?.length) {
      return;
    }

    const propertyControls = Subscribe._getPropertyControls(form);

    for (const propertyControlEntry of propertyControls) {
      const requestedPropertyId = propertyControlEntry[0];
      const propertyControl = propertyControlEntry[1];

      if (propertyControl.pristine) {
        const agreementProperty = agreementProperties.find(
          (ap) => ap.requestedPropertyId === requestedPropertyId
        );
        if (agreementProperty && agreementProperty.property?.type) {
          const subpropertiesControl = propertyControl?.get('properties');
          const subPropertyControl = subpropertiesControl?.get(agreementProperty.property.type);
          if (subPropertyControl && !hasValue(subPropertyControl.value)) {
            PropertyFormControl.setValue(subPropertyControl, agreementProperty.property);
          }
        }
      }
    }
  }

  /**
   * Fills the form controls with the related agreement documents
   * A form control is only filled when it is pristine
   *
   * @param form the form to be prefilled
   * @param documents the documents to fill it with
   */
  static prefillFormDocuments(
    form: UntypedFormGroup | undefined,
    documents: AgreementDocumentRepresentation[] | undefined
  ): void {
    if (!documents?.length) {
      return;
    }

    const documentControls = <UntypedFormArray>form?.get('documents');
    for (const documentControl of documentControls?.controls || []) {
      if (documentControl?.pristine) {
        const documentId = documentControl.get('id')?.value;
        const agreementDocument = documents.find((d) => d.proposalDocumentId === documentId);
        if (agreementDocument && !documentControl.get('value')?.value) {
          documentControl.get('value')?.setValue(agreementDocument.value);
          documentControl.get('signed')?.setValue(agreementDocument.signed);
        }
      }
    }
  }

  /**
   * Fills the value of the source form into the destinationForm
   * The destination control is only filled when it is pristine
   *
   * @param sourceForm a form that provides data to fill the destinationForm
   * @param destinationForm the form that gets filled
   */
  static prefillFormInForm(sourceForm: UntypedFormGroup, destinationForm: UntypedFormGroup): void {
    if (!sourceForm || !destinationForm) {
      return;
    }

    let destinationPropertiesControl: UntypedFormGroup;
    let destinationPropertyControls: AbstractControl[] = [];

    if (destinationForm.get('steps')) {
      const destinationStepsControl = <UntypedFormGroup>destinationForm.get('steps');
      if (destinationStepsControl) {
        for (const stepControlKey of Object.keys(destinationStepsControl.controls)) {
          const stepControl = destinationStepsControl.get(stepControlKey);
          const stepPropertiesControl = <UntypedFormGroup>stepControl?.get('properties');
          if (stepPropertiesControl) {
            for (const propertyControlKey of Object.keys(stepPropertiesControl.controls)) {
              const propertyControl = stepPropertiesControl.get(propertyControlKey);
              if (propertyControl) {
                destinationPropertyControls.push(propertyControl);
              }
            }
          }
        }
      }
    } else {
      destinationPropertiesControl = <UntypedFormGroup>destinationForm.get('properties');
      destinationPropertyControls = Object.values(destinationPropertiesControl?.controls);
    }

    const sourceProperties = Subscribe.formProperties(sourceForm.value).reverse();

    for (const destinationPropertyControl of destinationPropertyControls) {
      if (destinationPropertyControl && destinationPropertyControl.pristine) {
        const type = destinationPropertyControl.get('propertyType')?.value;

        if (type !== 'CUSTOM') {
          const property = sourceProperties.find((ap) => ap.type === type);
          if (property?.type) {
            const subpropertiesControl = destinationPropertyControl.get('properties');
            if (subpropertiesControl) {
              const subPropertyControl = subpropertiesControl.get(property.type);
              if (subPropertyControl) {
                PropertyFormControl.setValue(subPropertyControl, property);
              }
            }
          }
        }
      }
    }
  }

  static formProperties(formValue: any): PropertyRepresentation[] {
    const properties = [];
    if (formValue.steps) {
      for (const step of formValue.steps || []) {
        for (const property of Object.values(step.properties || {})) {
          properties.push(property);
        }
      }
    } else {
      for (const property of Object.values(
        formValue.properties || formValue.requestedProperties || {}
      )) {
        properties.push(property);
      }
    }

    return properties
      .map((value) => Subscribe._formValueToProperty(value))
      .filter((property) => !!property && hasValue(property));
  }

  static formAgreementProperties(formValue: any): AgreementPropertyRepresentation[] {
    if (!formValue) {
      return [];
    }
    const properties = [];
    for (const step of formValue.items || []) {
      for (const property of Object.entries(step.properties || {})) {
        properties.push(property);
      }
    }

    for (const property of Object.entries(formValue.requestedProperties || {})) {
      properties.push(property);
    }

    return properties.map((entry) => Subscribe._formValueToAgreementProperty(entry));
  }

  static formIntermediateAgeementData(
    formValue: any,
    languageCode: string
  ): IntermediateAgreementDataRepresentation {
    const agreementProperties = Subscribe.formAgreementProperties(formValue).filter((ap) =>
      hasValue(ap.property)
    );
    return {
      agreementProperties: agreementProperties,
      language: languageCode,
      documents: formValue.documents
        ? Subscribe._formIntermediateAgeementDataDocuments(formValue.documents)
        : undefined,
    };
  }

  static intermediateAgeementDataHasEqualValue(
    intermediateAgreementData1: IntermediateAgreementDataRepresentation | undefined,
    intermediateAgreementData2: IntermediateAgreementDataRepresentation | undefined
  ): boolean {
    const agreementProperties1 = intermediateAgreementData1?.agreementProperties || [];
    const agreementProperties2 = intermediateAgreementData2?.agreementProperties || [];

    if (agreementProperties1.length !== agreementProperties2.length) {
      return false;
    }

    for (const agreementProperty1 of agreementProperties1) {
      const agreementProperty2 = agreementProperties2.find((ap) => {
        return ap.requestedPropertyId === agreementProperty1.requestedPropertyId;
      });
      if (!hasEqualValue(agreementProperty1?.property, agreementProperty2?.property)) {
        return false;
      }
    }

    const agreementDocuments1 = intermediateAgreementData1?.documents || [];
    const agreementDocuments2 = intermediateAgreementData2?.documents || [];

    if (agreementDocuments1.length !== agreementDocuments2.length) {
      return false;
    }

    for (const agreementDocument1 of agreementDocuments1) {
      const agreementDocument2 = agreementDocuments2.find((ad) => {
        return ad.proposalDocumentId === agreementDocument1.proposalDocumentId;
      });
      if (
        agreementDocument1.signed !== agreementDocument2?.signed ||
        agreementDocument1.value !== agreementDocument2?.value
      ) {
        return false;
      }
    }

    return true;
  }

  static createVerificationForm(verificationType?: ProposalRepresentation.VerificationEnum) {
    return new UntypedFormGroup({
      property: PropertyFormControl.createByType(
        verificationType === ProposalRepresentation.VerificationEnum.EMAIL
          ? PropertyRepresentation.TypeEnum.EMAIL
          : PropertyRepresentation.TypeEnum.MOBILE_PHONE,
        [CustomValidators.propertyRequired, CustomValidators.propertyValid]
      ),
    });
  }

  static createSendToEmailForm() {
    return new UntypedFormGroup({
      checked: new UntypedFormControl(true),
      email: PropertyFormControl.createByType(PropertyRepresentation.TypeEnum.EMAIL, [
        CustomValidators.propertyRequired,
        CustomValidators.propertyValid,
      ]),
    });
  }

  static updateValuesValidity(form: UntypedFormGroup) {
    const subPropertiesForm = <UntypedFormArray>form.get('properties');
    for (const control of Object.values(subPropertiesForm?.controls || {})) {
      const valueControl = control.get('value');
      if (valueControl) {
        valueControl.updateValueAndValidity();
      }
      Subscribe.updateValuesValidity(<UntypedFormGroup>control);
    }
  }

  private static _createProposalStepsForm(
    form: UntypedFormArray,
    language: ProposalLanguageRepresentation
  ) {
    const steps = language.steps || [];

    const sortedSteps = sortSteps(language.items, [...(steps ?? [])]);
    for (const step of sortedSteps) {
      form.push(Subscribe._createProposalStepForm(step));
    }
  }

  private static _createProposalStepForm(step: ProposalLanguageStepRepresentation) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(step.id),
      title: new UntypedFormControl(step.title),
      description: new UntypedFormControl(step.description),
      skipTo: new UntypedFormControl(step.skipTo),
    });
  }

  static createProposalPropertyForm(
    form: UntypedFormGroup,
    language: ProposalLanguageRepresentation | undefined,
    agreement: AgreementRepresentation | undefined,
    proposalProperty: RequestedPropertyRepresentation,
    isOwner: boolean,
    myProfile?: Profile | ProfileRepresentation
  ) {
    const newGroup = new UntypedFormGroup({
      checked: new UntypedFormControl(false),
      propertyType: new UntypedFormControl(null),
      properties: new UntypedFormGroup({}),
    });

    form.setControl(proposalProperty.requestedPropertyId || '', newGroup);

    const propertyControls = <FormGroup<any>>newGroup.get('properties');

    const properties = Subscribe.agreementProperties(
      proposalProperty,
      agreement,
      isOwner ? undefined : myProfile
    );
    for (const property of properties) {
      let p = property;
      if (isOwner && agreement === undefined) {
        p = <PropertyRepresentation>dummyPropertyForType(property.type);
      }

      const validators = [];

      if (!isReadonlyProperty(language, proposalProperty.requestedPropertyId)) {
        if (
          proposalProperty.requestedPropertyTypes?.[0].customFieldInfo?.type ===
          CustomFieldInfoRepresentation.TypeEnum.URL
        ) {
          validators.push(
            ProposalValidators.customPropertyUrlValidator(
              newGroup,
              proposalProperty.requestedPropertyId
            )
          );
        }

        if (!isOwner) {
          if (isRequiredProperty(language, proposalProperty.requestedPropertyId)) {
            validators.push(
              ProposalValidators.propertyRequired(newGroup, proposalProperty.requestedPropertyId)
            );
            if (
              proposalProperty.requestedPropertyTypes?.[0].customFieldInfo?.type ===
              CustomFieldInfoRepresentation.TypeEnum.TOGGLE
            ) {
              validators.push(
                ProposalValidators.propertyRequiredTrue(
                  newGroup,
                  proposalProperty.requestedPropertyId
                )
              );
            }
          } else {
            validators.push(ProposalValidators.propertyOptional(newGroup));
          }
        }

        const maxValues =
          proposalProperty?.requestedPropertyTypes?.[0]?.customFieldInfo?.maxAmountOfValues;
        if (maxValues) {
          validators.push(ProposalValidators.maxValuesValidator(newGroup, maxValues));
        }
      }

      const propertyControl = PropertyFormControl.create(p, {validators});
      if (property?.type) {
        propertyControls.setControl(property.type, propertyControl);
      }

      if (hasValue(property)) {
        propertyControl.markAllAsTouched();
      }
    }

    const propertyTypeControl = newGroup.get('propertyType');

    if (propertyTypeControl) {
      if (isRequiredProperty(language, proposalProperty.requestedPropertyId)) {
        propertyTypeControl.setValidators(ProposalValidators.propertyTypeRequired);
        propertyTypeControl.updateValueAndValidity();
      }

      if (!myProfile) {
        const selectedPropType = properties[0]?.type;
        if (selectedPropType) {
          propertyTypeControl.setValue(selectedPropType);
        }
      }

      const propertyTypes = [...(proposalProperty.requestedPropertyTypes || [])]
        ?.sort((p1, p2) => {
          return (p1.orderIndex ?? Infinity) < (p2.orderIndex ?? Infinity) ? -1 : 1;
        })
        .map((pi) => pi.type);
      if (propertyTypes.length === 1) {
        propertyTypeControl.setValue(propertyTypes[0]);
      } else {
        // Preselect property type if a property exists in the given profile
        const existingProperty = Object.values<PropertyRepresentation>(propertyControls.value).find(
          (property) => {
            const profileProperties = getPropertiesOfType(myProfile, property.type);
            return !!profileProperties.find((prop) => hasEqualValue(prop, property));
          }
        );

        const propertyWithValue = Object.values<PropertyRepresentation>(
          propertyControls.value
        ).find((property) => !!property.value);

        if (existingProperty) {
          propertyTypeControl.setValue(existingProperty.type);
        } else if (propertyWithValue) {
          propertyTypeControl.setValue(propertyWithValue.type);
        } else {
          propertyTypeControl.setValue(propertyTypes[0]);
        }
      }
    }

    const checkedControl = newGroup.get('checked');
    const propertyControl = propertyControls.get(propertyTypeControl?.value);

    if (checkedControl && agreement?.agreementData?.confirmed && propertyControl?.value?.value) {
      checkedControl.setValue(true);
    }
  }

  private static _createProposalTextTemplateForm(
    form: UntypedFormArray,
    textTemplate: ProposalLanguageTextTemplateRepresentation
  ) {
    const textTemplateForm = new UntypedFormGroup({
      id: new UntypedFormControl(textTemplate.id),
      template: new UntypedFormControl(textTemplate.template),
    });
    form.push(textTemplateForm);
  }

  private static _isOwner(
    proposal?: ProposalRepresentation,
    myProfile?: Profile | ProfileRepresentation
  ) {
    return proposal?.ownerId === myProfile?.id;
  }

  private static _formValueToProperty(formValue: any) {
    return formValue.properties[formValue.propertyType];
  }

  private static _formValueToAgreementProperty(formEntry: any[]) {
    const formKey = formEntry[0];
    const formValue = formEntry[1];
    return {
      requestedPropertyId: formKey,
      property: Subscribe._formValueToProperty(formValue),
    };
  }

  private static _getPropertyControls(
    form: UntypedFormGroup | undefined
  ): [string, AbstractControl][] {
    const stepsForm = <UntypedFormArray>form?.get('steps') || null;
    if (stepsForm?.controls?.length) {
      let stepsFormPropertiesControls: [string, AbstractControl][] = [];
      for (const stepForm of stepsForm.controls) {
        const stepPropertiesForm = <UntypedFormGroup>stepForm.get('properties') || null;
        stepsFormPropertiesControls = [
          ...stepsFormPropertiesControls,
          ...Object.entries(stepPropertiesForm?.controls),
        ];
      }
      return stepsFormPropertiesControls;
    } else {
      const propertiesControl = <UntypedFormGroup>form?.get('properties');
      return Object.entries(propertiesControl?.controls || []);
    }
  }

  private static _formIntermediateAgeementDataDocuments(documents?: any[]): AgreementDocument[] {
    return (documents || [])
      .filter((document) => !!document)
      .filter((document) => document.type === 'TEMPLATE' || document.type === 'FILE')
      .map((document) => {
        return {
          proposalDocumentId: document.id,
          value: document.value,
          signed: document.signed,
        };
      });
  }
}
