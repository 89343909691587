import {BreakpointObserver} from '@angular/cdk/layout';
import {Overlay, OverlayRef, OverlaySizeConfig, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType, DomPortalOutlet} from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Inject,
  Injectable,
  Injector,
  Optional,
  SimpleChange,
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, filter, startWith, Subscription} from 'rxjs';

import {OverlayComponent} from './overlay.component';
import {OverlayRootContainerComponent} from './overlay-container/overlay-root-container';
import {modalSizeToPixels} from './overlay-functions';
import {OVERLAY_CDK_CONTAINER} from './overlay-theming';

export enum ModalSize {
  DYNAMIC = 'DYNAMIC',
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

interface OverlayOpts<T> {
  title?: string;
  titleOpts?: {[key: string]: string};
  hasTitleBar?: boolean;
  modalSize?: ModalSize;
  closeOnBackdropClick?: boolean;
  closeOnNavigate?: boolean;
  shadowDOM?: boolean;
  backgroundColor?: string;
  init?: (component: T) => void;
  close?: (component: T) => void;
}

interface OverlayConfig {
  overlayRef: OverlayRef;
  componentRef: ComponentRef<any>;
  overlayComponent: OverlayComponent<any>;
  hostElement: ElementRef;
  shadowDom: boolean;
  subscriptions: Subscription;
  close?: (component: any) => void;
}

@Injectable({providedIn: 'root'})
export class OverlayService {
  overlays: OverlayConfig[] = [];

  private _portalHost = new DomPortalOutlet(
    document.body,
    this._resolver,
    this._appRef,
    this._injector,
    document
  );
  private _rootContainerRef: ComponentRef<OverlayRootContainerComponent> | undefined;

  constructor(
    private _overlay: Overlay,
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _appRef: ApplicationRef,
    private _breakpointObserver: BreakpointObserver,
    @Inject(OVERLAY_CDK_CONTAINER) private _cdkContainer$: BehaviorSubject<any>,
    @Optional() private _router: Router
  ) {
    setTimeout(() => {
      this.createRootOverlayContainer();
    }, 0);
  }

  createModal<T>(
    hostElement: ElementRef,
    component: ComponentType<T>,
    config?: OverlayOpts<T>
  ): OverlayComponent<T> | undefined {
    const shadowDom = config?.shadowDOM ?? true;

    const hasParentContainer = !!hostElement.nativeElement.closest('ax-overlay-container');
    const container = this._getContainer(hostElement.nativeElement);
    container?.classList.add('scrollblock');
    const outlet = this._getOutlet(container, shadowDom);
    outlet?.classList.remove('empty');

    if (!container) {
      console.error('no container found for overlay');
      return undefined;
    }

    const cdkOverlayContainer = this._getCdkContainer(outlet, shadowDom);
    cdkOverlayContainer?.classList.remove('empty');
    this._cdkContainer$.next(cdkOverlayContainer);

    const componentFactory = this._resolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(this._injector);

    const previousInstance = {...componentRef.instance};

    if (config?.init) {
      config?.init(componentRef.instance);
    }

    const {
      title,
      titleOpts,
      hasTitleBar,
      backgroundColor,
      modalSize,
      closeOnBackdropClick,
      closeOnNavigate,
      close,
    } = config || {};
    const size = modalSize || ModalSize.SMALL;
    const overlayRef = this._initOverlay(size, hasParentContainer);
    const modalComponent = new ComponentPortal(OverlayComponent);
    const overlayWrapperRef = overlayRef.attach(modalComponent);

    const overlayComponent = <OverlayComponent<T>>overlayWrapperRef.instance;
    const subscriptions = new Subscription();
    const overlayConfig: OverlayConfig = {
      overlayRef,
      componentRef,
      overlayComponent,
      hostElement,
      shadowDom,
      subscriptions,
      close,
    };

    this.overlays.push(overlayConfig);

    if (closeOnBackdropClick !== false) {
      subscriptions.add(
        overlayRef.backdropClick().subscribe(() => this._closeModal(overlayConfig))
      );
    }

    if (closeOnNavigate && this._router) {
      subscriptions.add(
        this._router.events
          .pipe(filter((event) => event instanceof NavigationStart))
          .subscribe(() => this._closeModal(overlayConfig))
      );
    }

    overlayComponent.init(title, titleOpts, hasTitleBar, backgroundColor, size, componentRef);

    subscriptions.add(
      overlayComponent.viewContainerInserted.subscribe(() => {
        if (config?.init && (<any>componentRef.instance)['ngOnChanges']) {
          const changes: {[key: string]: SimpleChange} = {};
          for (const [key, currentValue] of Object.entries(<any>componentRef.instance)) {
            const previousValue = previousInstance[key as keyof typeof previousInstance];
            if (previousValue !== currentValue) {
              changes[key] = new SimpleChange(previousValue, currentValue, true);
            }
          }

          (<any>componentRef.instance)['ngOnChanges'](changes);
        }
      })
    );

    subscriptions.add(
      overlayComponent.didClickClose.subscribe(() => this._closeModal(overlayConfig))
    );
    subscriptions.add(
      overlayComponent.didResize
        .pipe(startWith(undefined))
        .subscribe(() =>
          overlayRef.updatePositionStrategy(this._calculatePositionStrategy(overlayComponent, size))
        )
    );
    subscriptions.add(
      overlayComponent.didResize.subscribe(() => overlayRef.updateSize(this._size(size)))
    );

    return overlayComponent;
  }

  private _initOverlay(
    modalSize: ModalSize = ModalSize.SMALL,
    hasParentContainer = false
  ): OverlayRef {
    return this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this._calculatePositionStrategy(undefined, modalSize),
      scrollStrategy: hasParentContainer ? undefined : this._overlay.scrollStrategies.block(),
      width: this._width(modalSize),
      height: this._height(modalSize),
      disposeOnNavigation: true,
    });
  }

  private _closeModal(overlay: OverlayConfig): void {
    if (overlay.close) {
      overlay.close(overlay.componentRef.instance);
    }

    overlay.overlayRef.detach();
    overlay.subscriptions.unsubscribe();

    const foundOverlayIndex = this.overlays.findIndex((o) => o === overlay);
    if (foundOverlayIndex > -1) {
      this.overlays.splice(foundOverlayIndex, 1);
    }

    if (this.overlays.length === 0) {
      const container = this._getContainer(overlay.hostElement.nativeElement);
      container?.classList.remove('scrollblock');
      const outlet = this._getOutlet(container, overlay.shadowDom);
      outlet?.classList.add('empty');
      const cdkContainer = this._getCdkContainer(outlet, overlay.shadowDom);
      cdkContainer?.classList.add('empty');

      this._setRootContainer();
    }
  }

  closeModal<T>(overlayComponent?: OverlayComponent<T>) {
    let overlayConfig = undefined;
    if (overlayComponent) {
      overlayConfig = this.overlays.find(
        (overlay) => overlay.overlayComponent === overlayComponent
      );
    } else {
      overlayConfig = this.overlays[this.overlays.length - 1];
    }
    if (overlayConfig) {
      this._closeModal(overlayConfig);
    }
  }

  closeAllModals() {
    const overlaysCopy = [...this.overlays];
    for (const overlay of overlaysCopy) {
      if (overlay) {
        this._closeModal(overlay);
      }
    }

    this.overlays = [];
  }

  createRootOverlayContainer() {
    try {
      this._rootContainerRef = this._portalHost.attach(
        new ComponentPortal(OverlayRootContainerComponent)
      );
      this._rootContainerRef.instance.afterViewInit$.subscribe(() => {
        this._setRootContainer();
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private _width(size: ModalSize): string {
    if (this._fullscreen(size)) {
      return `100%`;
    } else {
      return `${modalSizeToPixels(size)}px`;
    }
  }

  private _height(size: ModalSize): string {
    if (this._fullscreen(size)) {
      return `100%`;
    } else {
      return 'auto';
    }
  }

  private _size(size: ModalSize): OverlaySizeConfig {
    return {width: this._width(size), height: this._height(size)};
  }

  private _calculatePositionStrategy<T>(
    componentRef: OverlayComponent<T> | undefined,
    size: ModalSize
  ): PositionStrategy {
    const fullscreen = this._fullscreen(size);
    const top = fullscreen ? '0px' : '100px';
    const bottom = fullscreen ? '0px' : '50px';

    if (componentRef) {
      componentRef.elementRef.nativeElement.style.setProperty('--overlay-top', top);
      componentRef.elementRef.nativeElement.style.setProperty('--overlay-bottom', bottom);
    }

    return this._overlay.position().global().centerHorizontally().top(top);
  }

  private _breakpointMatched(): boolean {
    return this._breakpointObserver.isMatched('(max-width: 424px)');
  }

  private _fullscreen(size: ModalSize): boolean {
    return (
      size !== ModalSize.DYNAMIC &&
      (window.innerWidth <= modalSizeToPixels(size) || this._breakpointMatched())
    );
  }

  private _getContainer(element: Element): Element | undefined {
    const container = element.closest('ax-overlay-container');
    if (container) {
      return container;
    }

    this._setRootContainerTheme(element);

    let instance = this._rootContainerRef?.instance;
    if (!instance) {
      this.createRootOverlayContainer();
      instance = this._rootContainerRef?.instance;
    }

    return instance ? instance.elementRef.nativeElement : undefined;
  }

  private _setRootContainerTheme(element: Element): void {
    const instance = this._rootContainerRef?.instance;
    if (instance) {
      const computedStyleHost = getComputedStyle(element);
      instance.primaryColor = computedStyleHost.getPropertyValue('--primary-color-500');
      instance.accentColor = computedStyleHost.getPropertyValue('--accent-color-500');
      instance.warnColor = computedStyleHost.getPropertyValue('--warn-color-500');
      instance.fontFamily = computedStyleHost.getPropertyValue('--font');
      instance.fontColor = computedStyleHost.getPropertyValue('--font-color');
    }
  }

  private _setRootContainer() {
    const element = document.getElementsByTagName('ax-theme');
    const container = this._getContainer(element[0]);
    const outlet = this._getOutlet(container, false);
    const cdkOverlayContainer = this._getCdkContainer(outlet, false);
    this._cdkContainer$.next(cdkOverlayContainer);
  }

  private _getOutlet(container: Element | undefined, shadowDom: boolean): Element | undefined {
    return Array.from(container?.children || []).find((e) =>
      shadowDom
        ? e.localName.startsWith('ax-overlay-outlet-shadow-dom')
        : e.localName.startsWith('ax-overlay-outlet')
    );
  }

  private _getCdkContainer(outlet: Element | undefined, shadowDom: boolean): Element | undefined {
    const children = Array.from(
      (shadowDom ? outlet?.shadowRoot?.children : outlet?.children) || []
    );
    const cdkOverlayContainer = children.find((e) => e.classList.contains('cdk-overlay-container'));
    if (cdkOverlayContainer) {
      return cdkOverlayContainer;
    } else {
      const themeComponent = children.find((e) => e.localName === 'ax-theme');
      return Array.from(themeComponent?.children || []).find((e) =>
        e.classList.contains('cdk-overlay-container')
      );
    }
  }
}
